$breakpoint-small-mobile: 374px;
$breakpoint-mobile: 572px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1199px;
$button-radius-normal: 2em;
$navigation-button-radius: 0.5em;
$button-height: calc(1.5em + 0.5rem + 2px);
$input-radius: 2em;
$input-color: #000000;
$custom-radio-radius: 2em;
$custom-radio-box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
  rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
$custom-radio-box-shadow-inset: inset 5px 5px 6px #1e262d,
  inset -5px -5px 6px #445665;
$drawer-custom-radio-box-shadow: 1px 1px 3px #706f6f, 0 1px 2px #5e5656;
$drawer-custom-radio-box-shadow-inset: 3px 3px 4px #1e262d inset,
  -3px -3px 3px #445665 inset;
$drawer-content-container-box-shadow: 2px 2px 4px #73737d inset,
  -2px -2px 4px #6e6e7d inset;
$input-height: calc(1.75em + 0.75rem + 2px);
$input-padding: 1.5em 1em;
$border: 1px solid;
$border-bottom: 1px dashed;
