$primary-color: #3dd3af;
$secondary-color: #a45ca4;
$default-bg-color: #fff;
$transparent: transparent;
$link-color: #0090c6;
$default-label: #3d3d3d;
$error-text: #ff0000;
$box-shadow: 0 50px 100px -10px rgba(0, 0, 0, 0.2);
$header-bg-color: white;
$header-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
$welcome-user-icon-color: #717171;
$welcome-user-text-color: #717171;
$primary-bg-color: rgba(255, 255, 255, 0.9);
$secondary-bg-color: rgba(255, 255, 255, 0.9);
$breadcrumb-bg-color: #dadada;
$primary-btn-bg-color: #3dd3af;
$primary-btn-hover-bg-color: #3d3d3d;
$primary-btn-color: white;
$primary-btn-hover-color: #7bffdf;
$primary-btn-border-color: transparent;
$primary-btn-hover-border-color: transparent;
$secondary-btn-bg-color: #fff;
$secondary-btn-hover-bg-color: #3d3d3d;
$secondary-btn-color: $secondary-color;
$secondary-btn-hover-color: #ffc9ff;
$secondary-btn-border-color: #d016d0;
$secondary-btn-hover-border-color: transparent;
$custom-radio-unselected-bg-color: white;
$custom-radio-unselected-color: #212529;
$custom-radio-unselected-border-color: transparent;
$custom-radio-selected-bg-color: #3d3d3d;
$custom-radio-selected-color: white;
$custom-radio-selected-border-color: white;
$global-nav-header-bg: #313e49;
$navigation-btn-bg-color: $link-color;
$navigation-btn-border-color: #fff;
$navigation-btn-icon-color: #fff;
$pop-up-button-color: $primary-btn-bg-color;
$pop-up-button-border-color: $primary-btn-border-color;
$pop-up-text-color: #1d2f3b;
$pop-up-border: #3d3d3d;
$pop-up-bg-color: white;
$pop-up-header-color: $header-bg-color;
$pop-up-btn-text-color: white;
$primary-container-border: #fff;
$secondary-container-border: #dfdfdf;
$info-banner-bg: #262626;
$info-banner-border: #313e49;
$info-banner-text-color: #ffffff;
$welcomeBack-banner-bg: #06688b;
$backdrop-color: rgba(0, 0, 0, 0.74);
$id-scan-window-bg: rgba(0, 0, 0, 0.75);
$active-text-color: #3d3d3d;
$password-show-icon-color: #a45ca4;
$password-hide-icon-color: #c9005e;
$header-elipse-icon-color: #506f69;
$loading-icon-color: $header-bg-color;
$scan-id-front-card-icon-color: #4f8585;
$scan-id-card-icon-color: #4f8585;
$user-icon-color: #4f8585;
$product-icon-color: $header-bg-color;
$textColor: #4e8484;
$content-drawer-bg-color: #d6d6d6;
$content-drawer-border-color: rgba(0, 0, 0, 0.125);
$content-drawer-muted-text-color: #868686;
$fund-account-border-color: #c1c1c1;
$terms-card-bg-color: #ffffff;
$terms-card-border-color: #0b0673;
$terms-card-icon-color: #aa3886;
$terms-card-title-color: #aa3886;
$terms-card-description-color: rgba(0, 0, 0, 0.6);
$divide-line-color: #d1d1d1;
$validInputColor: #32cd32;
$selected-btn-bg-color: $secondary-color;
$selected-btn-color: white;
$unselected-btn-bg-color: $default-bg-color;
$unselected-btn-border-color: $secondary-color;
$input-bg-color: #ffffff;
$input-border-color: $primary-container-border;
$stepper-line-color: #c1c4c4;
$stepper-active-color: white;
$stepper-inactive-bg-color: white;
$vault-header-bg-color: #ffffff;
$vault-product-remove-icon: #bf53d1;
$vault-header-color: #3d3d3d;
$vault-header-icon-bg-color: $secondary-color;
$vault-header-icon-color: white;
$vault-card-bg-color: white;
$vault-product-icon-bg-color: white;
$vault-card-header-color: #00aeef;
$vault-card-text-color: #333333;
$fund-account-bg-color: white;
$fund-account-header: rgba(255, 255, 255, 0.9);
$fund-account-bg-header: $secondary-color;
$fund-account-heading-color: white;
$footer-bg-color: white;
$footer-item-text-color: #999999;
$footer-text-color: #333333;
$confirm-card-border-color: #c1c4c4;
$card-status-container: black;
$vault-body-container: rgba(255, 255, 255, 0.85);
$marketplace-bg-color: rgba(255, 255, 255, 0.5);
$form-choice-label-text-color: #333333;
$delete-icon-bg-color: #d6d6d6;
$card-added-tick-mark: $validInputColor;
$stepper-active-bg-color: #313e49;
$stepper-complete-color: $primary-color;
$stepper-active-color: #fff;
$product-card-bg-color: lightgrey;
$fund-product-card-bg-color: $primary-btn-bg-color;
$fund-product-card-text-color: #fff;
$nav-tab-active-color: #3d3d3d;
$nav-tab-background-color: transparent;
$nav-tab-border-color: $secondary-color;
$breadcrumb-text-color: white;
$beneficiary-tax-residency-container-bg-color: lightgrey;
$scroll-bar-hover-color: #24b9a2;
$scroll-bar-bg-color: #24b9a2;
$scroll-bar-track-color: #788289;
$product-status-in-review-bg-color: #ffa500;
$product-status-is-completed-bg-color: $validInputColor;
$product-status-is-rejected-bg-color: #ff4500;
$product-status-in-progress-bg-color: #06688b;
:export {
  stepperCompleted: $stepper-complete-color;
  stepperActive: $stepper-active-bg-color;
  stepperTextColor: $stepper-active-color;
}
